import { Component, Inject, OnInit } from '@angular/core';
import { FacadeService } from '../../facade/facade.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
@Component({
  selector: 'app-createversion',
  templateUrl: './createversion.component.html',
  styleUrls: ['./createversion.component.scss'],
})
export class CreateversionComponent implements OnInit {
  selectedECRN: any = "";
  ECRNDescription: any = "";
  ECRNReason: any = "";
  ECRNDate = new Date();
  NotproductRelated = false;
  productError: any = "";
  userId = parseInt(sessionStorage.getItem('userId'));
  docId = parseInt(sessionStorage.getItem('docId'));
  NewdocId : number= 0;
  saveAndSendApprovalDisplay: boolean;
  AreYouSureText: string;
  apiResponse: any;
  responseError: any;
  docsDetailsData: any;
  workflowDefinitionId: string = "";
  form: FormGroup;
  docsTypesData: any = [];
  departmentsData: any = [];
  workFlowData: any;
  fileName: any = '';
  selectedFile: boolean;
  selectedProduct: any;
  filteredproduct: any;
  filesize: any;
  selectedDocType: any;
  selectedWorkflow: any;

  title: any;
  department: any = '';
  type: any = '';
  reviewDate: any = '';
  expirationDate: any = null;
  effectiveDate: any = '';
  Remarks: any = '';
  Description: any = '';

  titleError: any;
  departmentError: any;
  docTypeError: any;
  workFlowError: any;
  EfError: any;
  RError: any;
  ExError: any;
  fileError: any = '';
  todayDate: Date;
  filteredproductline: any;
  documentTypeId: any;
  revision: any;
  showLoading: boolean;
  minDate: Date;
  SelectedFileName: any;
  SelectedFileNameExist: boolean;
  doccode: any;
  // ECRN:any;
  // IsFromCMS:boolean;
  alternatedDocId: any = "";
  ApprovedFile: any;
  productLineData = [];
  selectedProductLine: any;
  ECNLevelData = [];
  selectedECN: any;
  ecrnDateError: any = "";
  ECRNDescriptionError = "";
  ECRNReasonError = "";
  ShowECRN = false;
  disablePL:boolean = false;
  public moduleArray: any[] = [
    {
      Dmid: 0,
      DocumentId: 0,
      ModuleId: 1,
      Active: true,
    },
  ];

  public standardsArray: any[] = [];
  productsData=[];
  productGuidURL: any;
  prodroles: boolean = false;
  prodrolesdisplay: boolean = false;
  prcols: any[];
  prodrolesdata: any[];
  constructor(
    private fb: FormBuilder,
    private _facadeService: FacadeService,
    private datePipe: DatePipe,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object,) {
    this.form = this.fb.group({
      name: [''],
      avatar: null,
    });
  }

  ngOnInit(): void {
    this.showLoading = true;
    this.ECNLevelData = [
      { name: 'Minor', value: 1, code: true },
      { name: 'Major', value: 2, code: false },
    ]
    this.selectedECN = this.ECNLevelData[1];
    this.prcols = [
      { field: 'profileDisplayName', header: 'Approval Level' },
      { field: 'profileName', header: 'Profile' },
      { field: 'department', header: 'Department' },
      { field: 'role', header: 'Role' }
    ];
    this.getDepartments();
    // if (this.baseUrl["API_ENDPOINTS"]["ISPDMFlagActive"]) {
    //   this.getProductsList();
    // }
    this.minDate = new Date();
  }

  //Get document details by document identifier
  getDocDetailsById() {
    //debugger;
    this.showLoading = true;
    var userFeature = false;
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="DMS_SF_Admin");
        if(featureExist!=null)
          userFeature = true;
      }
    }
    var useId = userFeature?0:parseInt(sessionStorage.getItem('userId'));
    this._facadeService.getDocDetailsById(this.docId,useId).subscribe(
      (data) => {
        this.docsDetailsData = data.returnObject;
        this.doccode = data.returnObject.code;
        this.alternatedDocId = data.returnObject.alternatedDocId;
        if (this.alternatedDocId === 'null')
          this.alternatedDocId = "";
        this.workflowDefinitionId = this.docsDetailsData.workflowDefinitionId;
        this.title = "";
        this.selectedECN = this.ECNLevelData.find(m => m.code == this.docsDetailsData.isMinor);
        this.Description = this.docsDetailsData.description;
        if (this.Description === "null") { this.Description = ''; }
        this.Remarks = this.docsDetailsData.remarks;
        if (this.Remarks === "null") { this.Remarks = ''; }
        this.NewdocId = 0;
        this.effectiveDate = null;
        this.expirationDate = null;
        this.reviewDate = null;
        this.revision = this.docsDetailsData.revision;
        var dept = this.departmentsData.find(x => x.departmentId === this.docsDetailsData.departmentId)
        this.department = dept;
        var doctype = this.docsTypesData.find(x => x.documentTypeId === this.docsDetailsData.typeId)
        this.selectedDocType = doctype;
      
        if (this.selectedDocType.creationSource == "CMS")
          this.ShowECRN = true;
        else
          this.ShowECRN = false;
        if (this.docsDetailsData.userFileName != '' && this.docsDetailsData.userFileName != undefined) {
          this.SelectedFileNameExist = true;
          this.SelectedFileName = this.docsDetailsData.userFileName;
        }
        if (this.docsDetailsData.userFinalEditableFileName != '' && this.docsDetailsData.userFinalEditableFileName != undefined && this.docsDetailsData.userFinalEditableFileName != null) {
          if (this.docsDetailsData.finalEditableFileName != '' && this.docsDetailsData.finalEditableFileName != undefined && this.docsDetailsData.finalEditableFileName != null) {

            this.ApprovedFile = this.docsDetailsData.userFinalEditableFileName;
          }
          else if (this.docsDetailsData.prePublishedFileName != '' && this.docsDetailsData.prePublishedFileName != undefined && this.docsDetailsData.prePublishedFileName != null) {

            this.ApprovedFile = this.docsDetailsData.userFinalEditableFileName;
          }
        }
        else {
          this.ApprovedFile = this.docsDetailsData.userFileName;
        }
        if (this.selectedDocType != null && this.selectedDocType != "" && this.selectedDocType != undefined && this.selectedDocType.productRelated)
        {
          this.NotproductRelated = false;
          this.getProductsList();
        }
        else {
          this.NotproductRelated = true;
          this.GetProductLines(this.docsDetailsData.productLineId);
        }
        // this.bindWorkFlowsList(this.docsDetailsData.productLineId,this.docsDetailsData.isMinor,this.docsDetailsData.typeId, this.docsDetailsData.workflowDefinitionId);
        // this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  SelectPL()
  {
    this.productError = "";
    if(this.selectedProduct != null && this.selectedProduct != undefined)
    {
      this.GetProductLines(this.selectedProduct.productLineID);
      this.disablePL = true;
    }
    else
      this.GetProductLines('');
  }

  GetProductLines(prodLine) {
    this.showLoading = true;
    this.productLineData = [];
    this._facadeService.GetProductLines().subscribe(
      (data) => {
        data.returnObject.forEach(element => {
          this.productLineData.push(element);
        });
        this.productLineData.push({ id: "-1", "name": "ALL/Non-Specific" });
          if(this.NotproductRelated){
                  this.selectedProductLine=this.productLineData.find(m=>m.id=="-1");
          }
          else{
            if (prodLine != "" && prodLine != null && prodLine != undefined) {
              this.selectedProductLine = this.productLineData.find(m => m.id.toUpperCase() == prodLine.toUpperCase());
            }
            else{
              this.selectedProductLine=this.productLineData.find(m=>m.id=="-1");
            } 
          }
        this.getWorkFlowsList();
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  filterProductLine(event) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.productLineData.length; i++) {
      let productline = this.productLineData[i];
      if (productline.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(productline);
      }
    }
    this.filteredproductline = filtered;
  }

  getProductsList() {
    this.productsData=[];
    this.showLoading = true;
    this._facadeService.getProductsList().subscribe(
      (data) => {
        this.productsData.push({productGuid:"-1","productName":"Generic"});
        data.returnObject.forEach(element => {
          this.productsData.push(element);
        });
        if (this.productGuidURL != "" && this.productGuidURL != null && this.productGuidURL != undefined) {
          var selProdct = this.productsData.find(x => x.productGuid === this.productGuidURL)
          this.selectedProduct = selProdct;
        }
        else {
          if (this.docsDetailsData != null && this.docsDetailsData != "" && this.docsDetailsData != undefined &&
            this.docsDetailsData.productGuid !== null && this.docsDetailsData.productGuid !== '' &&
            this.docsDetailsData.productGuid !== undefined && this.docsDetailsData.productGuid !== 'null') {
            var selProdct = this.productsData.find(x => x.productGuid === this.docsDetailsData.productGuid)
            this.selectedProduct = selProdct;
          }
        }
        if (this.selectedDocType != null && this.selectedDocType != "" && this.selectedDocType != undefined && this.selectedDocType.productRelated) {
          this.NotproductRelated = false;
        }
        else {
          this.NotproductRelated = true;
          this.selectedProduct = "";
        }
        this.SelectPL();
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  filterProduct(event) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.productsData.length; i++) {
      let product = this.productsData[i];
      if (product.productName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(product);
      }
    }
    this.filteredproduct = filtered;
  }

  getDepartments() {
    this._facadeService.getAllDepartments().subscribe(
      (data) => {
        this.departmentsData = data.returnObject;
        this.getDocsTypes();
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  getDocsTypes() {
    this._facadeService.getDocsTypes().subscribe(
      (data) => {
        this.docsTypesData = data.returnObject;
        this.getDocDetailsById();
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  getWorkFlowsList() {
    this.showLoading = true;
    this.workFlowError = "";
    this.docTypeError = "";
    this.selectedWorkflow = null;
    var docTypeId = 0;
    if (this.selectedDocType != null && this.selectedDocType != "" && this.selectedDocType != undefined) {
      docTypeId = this.selectedDocType.documentTypeId;
    }

    let minor = false;
    if (this.selectedECN != null && this.selectedECN != "" && this.selectedECN != undefined) {
      minor = this.selectedECN.value == 1 ? true : false;
    }
    let productlineId = "";
    if (this.selectedProductLine != null && this.selectedProductLine != "" && this.selectedProductLine != undefined) {
      if (this.selectedProductLine.id != "-1") {
        productlineId = this.selectedProductLine.id;
      }
    }
    var workFlowModel = {
      setupId: 0,
      documentTypeId: docTypeId,
      documentType: null,
      workflowDefinitionId: null,
      workflowDefinitionName: null,
      isDeleted: false,
      productLineIds: productlineId,
      isMinor: minor
    };
    this._facadeService.workFlowsList(workFlowModel).subscribe(
      (data) => {
        this.workFlowData = data.returnObject;
        if (this.workFlowData.length > 0) {
          if(this.workflowDefinitionId != "")
          {
            var workflow = this.workFlowData.find(x => x.workflowDefinitionId === this.workflowDefinitionId)
            if(workflow != null && workflow != undefined)
              this.selectedWorkflow = workflow; 
            else
              this.selectedWorkflow = this.workFlowData[0];
            this.workflowDefinitionId = "";
          }
          else
          {
            if(this.selectedProductLine!=null&&this.selectedProductLine!=""&&this.selectedProductLine!=undefined&&this.selectedProductLine.id=="-1"){
              this.workFlowData=this.workFlowData.filter(m=>m.productLineIds==null);
              this.selectedWorkflow= this.workFlowData[0];
            }
            else{
              this.selectedWorkflow = this.workFlowData[0];
            }
            this.workflowDefinitionId = "";
          }
        }
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  uploadFile(event) {
    this.fileError ="";
    const file = (event.target as HTMLInputElement).files[0];
    if(file.size > 100000000)
      this.fileError = "File should be less than 100MB.";
    else
    {
      this.fileName = file.name;
      this.selectedFile=true;
      this.filesize = Math.round(file.size / 1024) + " KB";
      this.form.patchValue({
        avatar: file,
      });
      this.form.get("avatar").updateValueAndValidity();
    }
  }

  removeFile() {
    this.fileName = "";
    this.selectedFile = false;
    this.filesize = "";
  }

  onUpload(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.form.patchValue({
      avatar: file,
    });
    this.form.get('avatar').updateValueAndValidity();
  }

  EffectiveDateChange() {
    this.EfError = "";
    this.RError = "";
    this.ExError = "";
    if (this.reviewDate != undefined && this.reviewDate != "" &&
      new Date(this.effectiveDate) > new Date(this.reviewDate)) {
      this.EfError = "effective date should not be greater than review date.";
      return;
    }
    else if (this.expirationDate != undefined && this.expirationDate != "" &&
      new Date(this.effectiveDate) > new Date(this.expirationDate)) {
      this.EfError = "effective date should not be greater than Expiration date.";
      return;
    }
  }

  ReviewDateChange() {
    this.EfError = "";
    this.RError = "";
    this.ExError = "";
    if (this.effectiveDate != undefined && this.effectiveDate != "" &&
      new Date(this.reviewDate) < new Date(this.effectiveDate)) {
      this.RError = "Review date should not be less than effective date";
      return;
    }
    else if (this.expirationDate != undefined && this.expirationDate != ""
      && new Date(this.reviewDate) > new Date(this.expirationDate)) {
      this.RError = "Review date should not be greater than expiration date.";
      return;
    }
  }

  ExpireDateChange() {
    this.expirationDate = null;
    this.EfError = "";
    this.RError = "";
    this.ExError = "";
    if (this.effectiveDate != undefined && this.effectiveDate != "" &&
      new Date(this.expirationDate) < new Date(this.effectiveDate)) {
      this.ExError = "Expiration date should not be less than effective date.";
      return;
    }
    else if (this.reviewDate != undefined && this.reviewDate != "" && new Date(this.expirationDate) < new Date(this.reviewDate)) {
      this.ExError = "expiration date should not be less than review date.";
      return;
    }
  }
  BackClick() {
    location.href = this.baseUrl["API_ENDPOINTS"]["DMSUI"] + 'docdetails';  }

  saveAndSendApprovalClick() {
    this.showLoading = false;
    this.saveAndSendApprovalDisplay = true;
    this.AreYouSureText = "Do you want to Save and Submit for Approval for this Document?";
  }
  GetNewCode(){
    this.createDoc('',true);
  }

  GetNewECN(action,from){
    this.showLoading = true;
    this._facadeService.GetNewECN().subscribe(
      (data) => {
        this.selectedECRN=data.returnObject;
          this.Save(action,from);
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  createDoc(action,from) {
    this.showLoading = true;
    this.titleError = '';
    this.departmentError = '';
    this.docTypeError = '';
    this.workFlowError = '';
    this.EfError = '';
    this.RError = '';
    this.ExError = '';
    this.productError = "";
    this.ecrnDateError = "";
    this.ECRNDescriptionError = "";
    this.ECRNReasonError = "";
    if (this.title == undefined || this.title == '') {
      this.titleError = 'Please enter document title.'; this.showLoading = false;
    }
    else if (this.department == undefined || this.department == '') {
      this.departmentError = 'Please select department.'; this.showLoading = false;
    }
    else if (this.selectedDocType == undefined || this.selectedDocType == '') {
      this.docTypeError = 'Please select document type.'; this.showLoading = false;
    }
    else if (this.selectedWorkflow == undefined || this.selectedWorkflow == null || this.selectedWorkflow == '') {
      this.workFlowError = 'Workflow not defined. Please contact administrator.'; this.showLoading = false;
    }
    else if (!this.NotproductRelated && (this.selectedProduct == "" || this.selectedProduct == null || this.selectedProduct == undefined)) {
      this.productError = "Please select product"; this.showLoading = false;
    }
    else if (action == "sendforapproval" && this.fileName == "") {
      this.fileError = "Please upload file."; this.showLoading = false;
    }
    else if (this.selectedDocType.creationSource == "CMS" && (this.ECRNDate == null || this.ECRNDate == undefined)) {
      this.ecrnDateError = "Please select Date"; this.showLoading = false;
    }
    else if (this.selectedDocType.creationSource == "CMS" && (this.ECRNDescription == null || this.ECRNDescription == "" || this.ECRNDescription == undefined)) {
      this.ECRNDescriptionError = "Please enter description of change"; this.showLoading = false;
    }
    else if (this.selectedDocType.creationSource == "CMS" && (this.ECRNReason == null || this.ECRNReason == "" || this.ECRNReason == undefined)) {
      this.ECRNReasonError = "Please enter reasons for change"; this.showLoading = false;
    }
    /*else if (this.reviewDate == undefined || this.reviewDate == '') {
      this.RError = 'Please select review date.';
    }
    else if (this.expirationDate == undefined || this.expirationDate == '') {
      this.ExError = 'Please select expiration date.';
    }*/
    /*else if (this.fileName == '') {
      this.fileError = 'Please upload file.';
    }*/

    /*else if(new Date(this.reviewDate)<new Date(this.effectiveDate)){
      this.RError = "Review date should not be less than effective date";
      return;
      }
      else if(new Date(this.reviewDate)>new Date(this.expirationDate)){
      this.RError = "Review date should not be greater than expiration date.";
      return;
      }
      else if(new Date(this.expirationDate)<new Date(this.effectiveDate)){
      this.ExError = "Expiration date should not be less than effective date.";
      return;
      }
      else if(new Date(this.expirationDate)<new Date(this.reviewDate)){
      this.ExError = "expiration date should not be less than review date.";
      return;
      }*/
    /*this.effectiveDate != undefined &&
    this.effectiveDate != "" &&
    this.reviewDate != undefined &&
    this.reviewDate != "" &&
    this.expirationDate != undefined &&
    this.expirationDate != "" &&*/
    else if (action === "sendforapproval") {
      this.showLoading = true;
      if (!this.NotproductRelated) {
        var model = {
          wfName: "",
          definitionId: this.selectedWorkflow.workflowDefinitionId,
          productGuid: this.selectedProduct.productGuid
        };
        this._facadeService.GetWorkflowApprovers(model).subscribe(
          (data) => {
            if (data.returnObject.length > 0) {
              this.prodrolesdata = data.returnObject.filter(x => x.productRelated == true && x.approversCount == 0);
              if (this.prodrolesdata != undefined && this.prodrolesdata != null) {
                if (this.prodrolesdata.length > 0) {
                  this.prodroles = true;
                  this.productError = "Product Team has missing approver(s).";
                  this.showLoading = false;
                }
                else
                  this.saveAndSendApprovalClick();
              }
              else
                this.saveAndSendApprovalClick();
            }
            else
              this.saveAndSendApprovalClick();
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
      else
        this.saveAndSendApprovalClick();
    }
    else {
      this.showLoading = true;
      if (!this.NotproductRelated) {
        var model = {
          wfName: "",
          definitionId: this.selectedWorkflow.workflowDefinitionId,
          productGuid: this.selectedProduct.productGuid
        };
        this._facadeService.GetWorkflowApprovers(model).subscribe(
          (data) => {
            if (data.returnObject.length > 0) {
              this.prodrolesdata = data.returnObject.filter(x => x.productRelated == true && x.approversCount == 0);
              if (this.prodrolesdata != undefined && this.prodrolesdata != null) {
                if (this.prodrolesdata.length > 0) {
                  this.prodroles = true;
                  this.productError = "Product Team has missing approver(s).";
                  this.showLoading = false;
                }
                else
                {
                  if(this.NewdocId <= 0 && this.ShowECRN)
                    this.GetNewECN(action,from);
                  else
                    this.Save(action,from);
                }
              }
              else
              {
                if(this.NewdocId <= 0 && this.ShowECRN)
                  this.GetNewECN(action,from);
                else
                  this.Save(action,from);
              }
            }
            else
            {
              if(this.NewdocId <= 0 && this.ShowECRN)
                this.GetNewECN(action,from);
              else
                this.Save(action,from);
            }
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
      else
      {
        if(this.NewdocId <= 0 && this.ShowECRN)
          this.GetNewECN(action,from);
        else
          this.Save(action,from);
      }
    }
  }
  Save(action,from) {
    this.showLoading = true;
    var formData: any = new FormData();
    var modleString = JSON.stringify(this.moduleArray);
    console.log(modleString);
    var standardString = JSON.stringify(this.standardsArray);
    console.log(standardString);
    var newRevision = parseFloat(this.revision) + 1;

    formData.append('NewFileStream', this.form.get('avatar').value);
    formData.append('Id', this.NewdocId);

    formData.append('PlantId', 1);
    formData.append('DepartmentId', this.department.departmentId);
    formData.append('Title', this.title);
    formData.append('Description', this.Description === "null" ? "" : this.Description);
    formData.append('Code', this.docsDetailsData.code);
    console.log("Selected workflow ID : " + this.selectedDocType.documentTypeId);
    formData.append('TypeId', this.selectedDocType.documentTypeId);
    if(this.selectedProduct!=null&&this.selectedProduct!=""&&this.selectedProduct!=undefined){
      if (this.selectedProduct.productGuid != null && this.selectedProduct.productGuid != undefined&&this.selectedProduct.productGuid!="") {
        if(this.selectedProduct.productGuid=="-1"){
          formData.append("ProductGuid", "");
        }
        else{
          formData.append("ProductGuid", this.selectedProduct.productGuid);
        }
      }
      else{
        formData.append("ProductGuid", "");
      }
    }
    else{
      formData.append("ProductGuid", "");
    }
    if (this.docsDetailsData.site != null && this.docsDetailsData.site != undefined) {
      formData.append("Site", this.docsDetailsData.site);
    }
    formData.append('ChangeDescription', this.ECRNDescription);
    formData.append('ChangeReasons', this.ECRNReason);
    formData.append('Ecrn', this.selectedECRN);
    if(this.ECRNDate!=null&&this.ECRNDate!=undefined){
      formData.append('ChangeDate', this.datePipe.transform(this.ECRNDate, 'MM/dd/yyyy'));
    }

    // if(this.ECRN!=null && this.ECRN!=""&&this.ECRN!=undefined){
    //   formData.append('Ecrn',this.ECRN);
    // }

    if (this.effectiveDate !== '' && this.effectiveDate !== null) {
      formData.append(
        'EffectiveDate',
        this.datePipe.transform(this.effectiveDate, 'MM/dd/yyyy')
      );
    }

    if (this.expirationDate !== null) {
      formData.append(
        'ExpirationDate',
        this.datePipe.transform(this.expirationDate, 'MM/dd/yyyy')
      );
    }
    formData.append('Status', 'New');

    let minor = false;
    if (this.selectedECN != null && this.selectedECN != "" && this.selectedECN != undefined) {
      minor = this.selectedECN.value == 1 ? true : false;
    }
    if (this.selectedProductLine != null && this.selectedProductLine != "" && this.selectedProductLine != undefined) {
      if (this.selectedProductLine.id != "-1") {
        formData.append("ProductLineId", this.selectedProductLine.id);
      }
    }
    else {
      formData.append("ProductLineId", "");
    }
    formData.append("IsMinor", minor);

    formData.append('Remarks', this.Remarks === "null" ? "" : this.Remarks);
    console.log("new revsion ::" + JSON.stringify(newRevision))
    formData.append('Revision', newRevision);
    formData.append('RevisionParentId', this.docId);
    if(this.alternatedDocId!=null&&this.alternatedDocId!=""&&this.alternatedDocId!=undefined&&this.alternatedDocId != "null"){
      formData.append('AlternatedDocId', this.alternatedDocId);
    }
    else{
      formData.append('AlternatedDocId', "");
    }
    
    if (this.reviewDate !== '' && this.reviewDate !== null) {
      formData.append(
        'ReviewDate',
        this.datePipe.transform(this.reviewDate, 'MM/dd/yyyy')
      );
    }
    formData.append('IsArchived', 'N');

    formData.append('EcoParentId', 0);
    formData.append('HasWorkflow', true);
    formData.append('WorkflowDefinitionId', this.selectedWorkflow.workflowDefinitionId);
    // console.log("Selected workflow : " + this.selectedWorkflow);
    // console.log("Selected workflow ID : " + this.selectedWorkflow.workflowDefinitionId);
    formData.append('CreatedBy', this.userId);
    formData.append('StandardClausesJson', standardString);
    formData.append('ModulesJson', modleString);
    if (action === 'sendforapproval' || action === 'confirm') {
      formData.append("Operation", "rev-sendforapproval");
    }
    else {
      formData.append("Operation", "rev");
    }
    formData.forEach((value, key) => {
      console.log(key + ' ' + value);
    });
    this.showLoading = true;
    if(this.NewdocId > 0)
    {
      this._facadeService.UpdateDocument(formData)
      .subscribe(
        (data) => { 
          console.log(data);
          this.apiResponse = data;
          if(this.apiResponse.returnCode >= 0){         
            this.saveAndSendApprovalDisplay=false;           
            var TileStatus= (action=="sendforapproval"||action=="confirm")?"In-Process":"New";
              location.href=this.baseUrl["API_ENDPOINTS"]["DMSUI"]+'mydocs?TileStatus='+TileStatus;
          }
          else{
            this.responseError = this.apiResponse.returnMessage;
            this.showLoading = false;
          }
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
    else
    { 
      this._facadeService.CreateDocument(formData)
      .subscribe(
        (data) => {
          this.apiResponse = data;
          if (this.apiResponse.returnCode >= 0) {
            this.saveAndSendApprovalDisplay=false; 
            if(from==true){
              this.showLoading = false;
              this.NewdocId=this.apiResponse.returnObject.id;  
            }
             else{
              var TileStatus= (action=="sendforapproval"||action=="confirm")?"In-Process":"New";
              location.href = this.baseUrl["API_ENDPOINTS"]["DMSUI"] + 'mydocs?TileStatus=' + TileStatus;
             }
          }
          else {
            this.responseError = this.apiResponse.returnMessage;
            this.showLoading = false;
          }
          console.log(data);
          
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
  }
  GetDownloadFile() {
    if (this.docsDetailsData.userFinalEditableFileName != '' && this.docsDetailsData.userFinalEditableFileName != undefined && this.docsDetailsData.userFinalEditableFileName != null) {
      if (this.docsDetailsData.finalEditableFileName != '' && this.docsDetailsData.finalEditableFileName != undefined && this.docsDetailsData.finalEditableFileName != null) {

        this.showLoading = true;
        var newname = this.docsDetailsData.userFinalEditableFileName.substr(0, this.docsDetailsData.userFinalEditableFileName.lastIndexOf('.'));
        this.Download(this.docsDetailsData.fileName, newname, this.docsDetailsData.userFinalEditableFileName);
      }
      else if (this.docsDetailsData.prePublishedFileName != '' && this.docsDetailsData.prePublishedFileName != undefined && this.docsDetailsData.prePublishedFileName != null) {

        this.showLoading = true;
        var newname = this.docsDetailsData.userFinalEditableFileName.substr(0, this.docsDetailsData.userFinalEditableFileName.lastIndexOf('.'));
        this.Download(this.docsDetailsData.fileName, newname, this.docsDetailsData.userFinalEditableFileName);
      }
    }
    else {
      this.showLoading = true;
      var newname = this.docsDetailsData.userFileName.substr(0, this.docsDetailsData.userFileName.lastIndexOf('.'));
      this.Download(this.docsDetailsData.fileName, newname, this.docsDetailsData.userFileName);
    }
  }
  Download(filename, newname, userfilename) {
    this._facadeService.downloadFile(filename, newname).subscribe(response => {
      response.filename = userfilename;
      let url = window.URL.createObjectURL(response);
      var anchor = document.createElement("a");
      anchor.download = userfilename;
      anchor.href = url;
      anchor.click();
      anchor.remove();
      // window.open(url, '_blank').focus();
      this.showLoading = false;
    }),
      error => console.log('Error downloading the file'),
      () => console.info('File downloaded successfully');

  }
}

