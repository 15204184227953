import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { FacadeService,Workflow, Ecn } from '../../facade/facade.service';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { PrintService } from "../../services/print.service";
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-eco',
  templateUrl: './eco.component.html',
  styleUrls: ['./eco.component.scss']
})
export class EcoComponent implements OnInit {
  ShowECRN=false;
  selectedECRN:any="";
  ECRNDescription:any="";
  ECRNReason:any="";
  ECRNDate=new Date();
  filteredproductline:any;
  userId = parseInt(sessionStorage.getItem('userId'));
  docId = parseInt(sessionStorage.getItem('docId'));
  NewdocId : number= 0;
  userGuid = sessionStorage.getItem('userGuid');
  NotproductRelated=false;
  productError:any="";
  selectedProduct:any;
  filteredproduct:any;
  saveAndSendApprovalDisplay:boolean;
  AreYouSureText:string;
  apiResponse: any;
  responseError: any;
  docsDetailsData: any;
  workflowDefinitionId: String;
  form: FormGroup;
  docsTypesData: any= [];
  departmentsData: any=[];
  workFlowData: any;
  fileName: any = '';
  cols: any[];
  selectedDocType: any;
  selectedWorkflow: any;
  alternatedDocId:any="";
  title: any;
  department: any = '';
  type: any = '';
  reviewDate: any = '';
  expirationDate: any = '';
  effectiveDate: any = '';
  Remarks: any = '';
  Description: any = '';

  selectedFile:boolean;
  filesize:any;
  revision:any;
  titleError: any;
  departmentError: any;
  docTypeError: any;
  workFlowError: any;
  EfError: any;
  RError: any;
  ExError: any;
  fileError: any = '';
  ECODocs: any;
  todayDate: Date;
  documentTypeId: any;
  showLoading:boolean;
  minDate:Date;
  SelectedFileName:any;
  SelectedFileNameExist:boolean;
  prodroles:boolean = false;
  prodrolesdisplay:boolean = false;
  prcols:any[];
  prodrolesdata:any[];
  productLineData=[];
  selectedProductLine:any;
  ECNLevelData=[];
  selectedECN:any;
  ecrnDateError:any="";
  ECRNDescriptionError="";
  ECRNReasonError="";
  disablePL:boolean = false;
  
  public moduleArray: any[] = [
    {
      Dmid: 0,
      DocumentId: 0,
      ModuleId: 1,
      Active: true,
    },
  ];

  public standardsArray: any[] = [];
  filtereddoctype: any[];
  productsData=[];
  productGuidURL: string;
  constructor(
    private fb: FormBuilder,
    private _facadeService: FacadeService,
    public printService: PrintService,
    private datePipe: DatePipe,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object) {
      this.form = this.fb.group({
        name: [''],
        avatar: null,
      });
     }

  ngOnInit(): void {
    this.showLoading = true;
    this.ECNLevelData=[
      {name: 'Minor', value: 1,code:true},
      {name: 'Major', value: 2,code:false},
    ]
    this.selectedECN=this.ECNLevelData[1];
	  this.prcols = [
      { field: 'profileDisplayName', header: 'Approval Level'},
      { field: 'profileName', header: 'Profile'},
      { field: 'department', header: 'Department'},
      { field: 'role', header: 'Role'}
    ];
    this.getDepartments();
    this.getECODocuments();
    this.minDate=new Date();
  }

  saveAndSendApprovalClick(){
    this.showLoading = false;
    this.saveAndSendApprovalDisplay=true;
    this.AreYouSureText="Do you want to Save and Submit for Approval for this Document?";
    
  }
  SelectPL()
  {
    this.productError = "";
    if(this.selectedProduct != null && this.selectedProduct != undefined)
    {
      this.GetProductLines(this.selectedProduct.productLineID);
      this.disablePL = true;
    }
    else
      this.GetProductLines('');
  }

  getProductsList(){
    this.productsData=[];
    this._facadeService.getProductsList().subscribe(
      (data) => {
        this.productsData.push({productGuid:"-1","productName":"Generic"});
        data.returnObject.forEach(element => {
          this.productsData.push(element);
        });
        if(this.productGuidURL!="" && this.productGuidURL!=null && this.productGuidURL!=undefined){
          var selProdct = this.productsData.find(x => x.productGuid === this.productGuidURL)
        this.selectedProduct =  selProdct;
        }
        else{
          if(this.docsDetailsData!=null && this.docsDetailsData!="" && this.docsDetailsData!=undefined && 
          this.docsDetailsData.productGuid !== null && this.docsDetailsData.productGuid !=='' && 
          this.docsDetailsData.productGuid!==undefined && this.docsDetailsData.productGuid !== 'null'){       
            var selProdct = this.productsData.find(x => x.productGuid === this.docsDetailsData.productGuid)
            this.selectedProduct =  selProdct;
          }
        }
        if(this.selectedDocType!=null&&this.selectedDocType!=""&&this.selectedDocType!=undefined&&this.selectedDocType.productRelated){
          this.NotproductRelated=false;
        }
        else{
          this.NotproductRelated=true;
          this.selectedProduct="";
        }
        this.SelectPL();
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  filterProduct(event) {
    let filtered : any[] = [];
    let query = event.query;
    for(let i = 0; i < this.productsData.length; i++) {
        let product = this.productsData[i];
        if (product.productName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(product);
        }
    }    
    this.filteredproduct = filtered;
}

  //Get document details by document identifier
  getDocDetailsById() {
    this.showLoading = true;
    var userFeature = false;
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="DMS_SF_Admin");
        if(featureExist!=null)
          userFeature = true;
      }
    }
    var useId = userFeature?0:parseInt(sessionStorage.getItem('userId'));
    this._facadeService.getDocDetailsById(this.docId,useId).subscribe(
        (data) => {
        this.docsDetailsData = data.returnObject;
        this.workflowDefinitionId = this.docsDetailsData.workflowDefinitionId;
        this.title = "";
        if(this.docsDetailsData.alternatedDocId !== null && this.docsDetailsData.alternatedDocId !== 'null' && this.docsDetailsData.alternatedDocId !== undefined)
          this.alternatedDocId = this.docsDetailsData.alternatedDocId;
        else
          this.alternatedDocId = "";
        this.Description = this.docsDetailsData.description;
        if(this.Description === "null"){this.Description = '';}
          this.Remarks = this.docsDetailsData.remarks;
        if(this.Remarks === "null"){this.Remarks = '';}
          this.NewdocId = 0;
        if(this.docsDetailsData.effectiveDate !== null && this.docsDetailsData.effectiveDate !== "")
          this.effectiveDate = new Date(this.docsDetailsData.effectiveDate);
        else
          this.effectiveDate = null;
        if(this.docsDetailsData.expirationDate !== null)
          this.expirationDate = new Date(this.docsDetailsData.expirationDate);
        else
          this.expirationDate = null;
        this.effectiveDate = null;
        this.expirationDate = null;
        this.reviewDate = null;
        this.cols = [
          { field: 'code', header: 'Id', dynamicPlaceHolder: 'Search by Id' },
          { field: 'title', header: 'Title', dynamicPlaceHolder: 'Search by title' },
          { field: 'documentType', header: 'Type', dynamicPlaceHolder: 'Search by type' },
          { field: 'effectiveDate', header: 'Effective Date', dynamicPlaceHolder: 'Search by YYYY-MM-DD' },
          { field: 'expirationDate', header: 'Expiration Date', dynamicPlaceHolder: 'Search by YYYY-MM-DD' },
          { field: 'status', header: 'Status', dynamicPlaceHolder: 'Search by status' },
        ];

        var dept = this.departmentsData.find(x => x.departmentId === this.docsDetailsData.departmentId)
        this.department = dept;
        this.revision = this.docsDetailsData.revision;
        var doctype = this.docsTypesData.find(x => x.documentTypeId === this.docsDetailsData.typeId)
        this.selectedDocType =  doctype;
        this.selectedECN=this.ECNLevelData.find(m=>m.code==this.docsDetailsData.isMinor);
        if (this.selectedDocType.creationSource == "CMS")
          this.ShowECRN = true;
        else 
          this.ShowECRN = false;
       
        if(this.docsDetailsData.userFileName!='' && this.docsDetailsData.userFileName!=undefined){
          this.SelectedFileNameExist=true;
          this.SelectedFileName = this.docsDetailsData.userFileName;
        }
        if (this.selectedDocType != null && this.selectedDocType != "" && this.selectedDocType != undefined && this.selectedDocType.productRelated)
        {
          this.NotproductRelated = false;
          this.getProductsList();
        }
        else {
          this.NotproductRelated = true;
          this.GetProductLines(this.docsDetailsData.productLineId);
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  
  getDepartments() {
    this._facadeService.getAllDepartments().subscribe(
      (data) => {
        this.departmentsData = data.returnObject;
        this.getDocsTypes();
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  getDocsTypes() {
    this._facadeService.getDocsTypes().subscribe(
      (data) => {
        this.docsTypesData = data.returnObject;
        this.getDocDetailsById();
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  GetProductLines(prodLine){
    this.productLineData = [];
    this.showLoading = true;
    this._facadeService.GetProductLines().subscribe(
      (data) => {
        data.returnObject.forEach(element => {
          this.productLineData.push(element);
        });
        this.productLineData.push({id:"-1","name":"ALL/Non-Specific"});
        if(this.NotproductRelated){
          this.selectedProductLine=this.productLineData.find(m=>m.id=="-1");
  }
  else{
    if (prodLine != "" && prodLine != null && prodLine != undefined) {
      this.selectedProductLine = this.productLineData.find(m => m.id.toUpperCase() == prodLine.toUpperCase());
    }
    else{
      this.selectedProductLine=this.productLineData.find(m=>m.id=="-1");
    } 
  }
        this.getWorkFlowsList();
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  filterProductLine(event){
    let filtered : any[] = [];
      let query = event.query;
      for(let i = 0; i < this.productLineData.length; i++) {
          let productline = this.productLineData[i];
          if (productline.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
              filtered.push(productline);
          }
      }    
      this.filteredproductline = filtered;
      console.log('product line-----', this.filteredproductline);
  }
  getWorkFlowsList() {
    this.showLoading = true;
    this.workFlowError = "";
    this.docTypeError = "";
    this.selectedWorkflow = null;
    var docTypeId=0;
    if(this.selectedDocType!=null&&this.selectedDocType!=""&&this.selectedDocType!=undefined){
      docTypeId = this.selectedDocType.documentTypeId;
    }
  
    let minor=false;
    if(this.selectedECN!=null&&this.selectedECN!=""&&this.selectedECN!=undefined)
    {
      minor = this.selectedECN.value==1?true:false;
    }
    let productlineId="";
    if(this.selectedProductLine!=null&&this.selectedProductLine!=""&&this.selectedProductLine!=undefined)
    {
      if(this.selectedProductLine.id!="-1")
        productlineId =this.selectedProductLine.id;
    }
    var workFlowModel = {
      setupId: 0,
      documentTypeId: docTypeId,
     documentType: null,
      workflowDefinitionId: null,
      workflowDefinitionName: null,
      isDeleted: false,
      productLineIds:productlineId,
      isMinor:minor
    };
    this._facadeService.workFlowsList(workFlowModel).subscribe(
      (data) => {
        this.workFlowData = data.returnObject;
        if (this.workFlowData.length > 0) {
          if(this.workflowDefinitionId != "")
          {
            var workflow = this.workFlowData.find(x => x.workflowDefinitionId === this.workflowDefinitionId)
            if(workflow != null && workflow != undefined)
              this.selectedWorkflow = workflow; 
            else
              this.selectedWorkflow = this.workFlowData[0];
            this.workflowDefinitionId = "";
          }
          else
          {
            if(this.selectedProductLine!=null&&this.selectedProductLine!=""&&this.selectedProductLine!=undefined&&this.selectedProductLine.id=="-1"){
              this.workFlowData=this.workFlowData.filter(m=>m.productLineIds==null);
              this.selectedWorkflow= this.workFlowData[0];
            }
            else{
              this.selectedWorkflow = this.workFlowData[0];
            }
            this.workflowDefinitionId = "";
          }
        }
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  uploadFile(event) {
    this.fileError ="";
    const file = (event.target as HTMLInputElement).files[0];
    if(file.size > 100000000)
      this.fileError = "File should be less than 100MB.";
    else
    {
      this.fileName = file.name;
      this.selectedFile=true;
      this.filesize = Math.round(file.size / 1024) + " KB";
      this.form.patchValue({
        avatar: file,
      });
      this.form.get("avatar").updateValueAndValidity();
    }
  }
    
    removeFile() {
    this.fileName ="";
    this.selectedFile=false;
    this.filesize="";
    }

  onUpload(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.form.patchValue({
      avatar: file,
    });
    this.form.get('avatar').updateValueAndValidity();
  }

  getECODocuments() {
    this.showLoading = true;
    this._facadeService.getECODocuments(this.docId, 0).subscribe(
      (data) => {
        this.ECODocs = data.returnObject;
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  EffectiveDateChange(){
    this.EfError = "";
      this.RError = "";
      this.ExError = "";
     if(this.reviewDate != undefined && this.reviewDate != "" && 
     new Date(this.effectiveDate)>new Date(this.reviewDate)){
      this.EfError = "effective date should not be greater than review date.";
      return;
      }
    else if(this.expirationDate != undefined && this.expirationDate != "" && 
    new Date(this.effectiveDate)>new Date(this.expirationDate)){
     this.EfError = "effective date should not be greater than Expiration date.";
     return;
     }
  }
  
  ReviewDateChange(){
    this.EfError = "";
      this.RError = "";
      this.ExError = "";
    if(this.effectiveDate != undefined && this.effectiveDate != "" && 
   new Date(this.reviewDate)<new Date(this.effectiveDate)){
      this.RError = "Review date should not be less than effective date";
      return;
      }
      else if(this.expirationDate != undefined && this.expirationDate != ""
       && new Date(this.reviewDate)>new Date(this.expirationDate)){
        this.RError = "Review date should not be greater than expiration date.";
        return;
        }
  }
  
  ExpireDateChange(){
    this.EfError = "";
      this.RError = "";
      this.ExError = "";
     if(this.effectiveDate != undefined && this.effectiveDate != "" && 
     new Date(this.expirationDate)<new Date(this.effectiveDate)){
      this.ExError = "Expiration date should not be less than effective date.";
      return;
      }
      else if(this.reviewDate != undefined && this.reviewDate != "" &&new Date(this.expirationDate)<new Date(this.reviewDate)){
      this.ExError = "expiration date should not be less than review date.";
      return;
      }
  }
  GetNewCode(){
    this.createDoc('',true);
  }

  GetNewECN(action,from){
    this.showLoading = true;
    this._facadeService.GetNewECN().subscribe(
      (data) => {
        this.selectedECRN=data.returnObject;
        this.Save(action,from);
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  createDoc(action,from) {
    this.showLoading = true;
    this.titleError = '';
    this.departmentError = '';
    this.docTypeError = '';
    this.workFlowError = '';
    this.EfError = '';
    this.RError = '';
    this.ExError = '';
    this.productError="";
    this.ecrnDateError="";
    this.ECRNDescriptionError="";
    this.ECRNReasonError="";
    if (this.title == undefined || this.title == '') {
      this.titleError = 'Please enter document title.';this.showLoading = false;
    }
    else if (this.department == undefined || this.department == '') {
      this.departmentError = 'Please select department.';this.showLoading = false;
    }
    else if (this.selectedDocType == undefined || this.selectedDocType == '') {
      this.docTypeError = 'Please select document type.';this.showLoading = false;
    }
    else if (this.selectedWorkflow == undefined || this.selectedWorkflow == null || this.selectedWorkflow == '') {
      this.workFlowError = 'Workflow not defined. Please contact administrator.';this.showLoading = false;
    }
    else if(!this.NotproductRelated && (this.selectedProduct==""||this.selectedProduct==null||this.selectedProduct==undefined)){
      this.productError="Please select product";this.showLoading = false;
    }
    else if(this.effectiveDate === undefined || this.effectiveDate === null || this.effectiveDate ===''){
      this.EfError = 'Please select effective date.';this.showLoading = false;
    }
    else if (this.expirationDate == undefined || this.expirationDate == '') {
      this.ExError = 'Please select expiration date.';this.showLoading = false;
    }
    else if (action=="sendforapproval" && this.fileName == "") {
      this.fileError = "Please upload file.";this.showLoading = false;
    }
    else if (this.selectedDocType.creationSource == "CMS"&&(this.ECRNDate==null||this.ECRNDate==undefined)) {
      this.ecrnDateError="Please select Date";this.showLoading = false;
    }
    else if (this.selectedDocType.creationSource == "CMS"&&(this.ECRNDescription==null||this.ECRNDescription==""||this.ECRNDescription==undefined)) {
      this.ECRNDescriptionError="Please enter description of change";this.showLoading = false;
    }
    else if (this.selectedDocType.creationSource == "CMS"&&(this.ECRNReason==null||this.ECRNReason==""||this.ECRNReason==undefined)) {
      this.ECRNReasonError="Please enter reasons for change";this.showLoading = false;
    }
    /*else if (this.fileName == '') {
      this.fileError = 'Please upload file.';
    }*/    
   /* else if(new Date(this.reviewDate)<new Date(this.effectiveDate)){
      this.RError = "Review date should not be less than effective date";
      return;
      }
      else if(new Date(this.reviewDate)>new Date(this.expirationDate)){
      this.RError = "Review date should not be greater than expiration date.";
      return;
      }*/
      else if(new Date(this.expirationDate)<new Date(this.effectiveDate)){
      this.ExError = "Expiration date should not be less than effective date.";this.showLoading = false;
      return;
      }
      /*else if(new Date(this.expirationDate)<new Date(this.reviewDate)){
      this.ExError = "expiration date should not be less than review date.";
      return;
      }*/
    // if (this.fileName != 'Choose File') {
    //   this.fileError = '';
    // }
    /*
      this.reviewDate != undefined &&
      this.reviewDate != "" &&
     */
    else if(action === "sendforapproval"){
      if(!this.NotproductRelated){
        var model = {
          wfName: "",
          definitionId: this.selectedWorkflow.workflowDefinitionId,
          productGuid: this.selectedProduct.productGuid
        };
        this.showLoading = true;
        this._facadeService.GetWorkflowApprovers(model).subscribe(
          (data) => {
            if(data.returnObject.length>0){
              this.prodrolesdata = data.returnObject.filter(x=>x.productRelated == true && x.approversCount == 0);
              if(this.prodrolesdata != undefined && this.prodrolesdata != null)
              {
                if(this.prodrolesdata.length > 0)
                {
                  this.prodroles = true;
                  this.productError = "Product Team has missing approver(s)."
                }
                else
                  this.saveAndSendApprovalClick();
              }
              else
                this.saveAndSendApprovalClick();
            }
            else
              this.saveAndSendApprovalClick();
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
      else
        this.saveAndSendApprovalClick();
    }
    else{
      this.showLoading = true;
      if(!this.NotproductRelated){
        var model = {
          wfName: "",
          definitionId: this.selectedWorkflow.workflowDefinitionId,
          productGuid: this.selectedProduct.productGuid
        };
        this._facadeService.GetWorkflowApprovers(model).subscribe(
          (data) => {
            if(data.returnObject.length>0){
              this.prodrolesdata = data.returnObject.filter(x=>x.productRelated == true && x.approversCount == 0);
              if(this.prodrolesdata != undefined && this.prodrolesdata != null)
              {
                if(this.prodrolesdata.length > 0)
                {
                  this.prodroles = true;
                  this.productError = "Product Team has missing approver(s).";
                  this.showLoading = false;
                }
                else
                {
                  if(this.NewdocId <= 0 && this.ShowECRN)
                    this.GetNewECN(action,from);
                  else
                    this.Save(action,from);
                }
              }
              else
              {
                if(this.NewdocId <= 0 && this.ShowECRN)
                  this.GetNewECN(action,from);
                else
                  this.Save(action,from);
              }
            }
            else
            {
              if(this.NewdocId <= 0 && this.ShowECRN)
                this.GetNewECN(action,from);
              else
                this.Save(action,from);
            }
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
      else
      {
        if(this.NewdocId <= 0 && this.ShowECRN)
          this.GetNewECN(action,from);
        else
          this.Save(action,from);
      }
    }
  }
  Save(action,from)
  {
    this.showLoading = true;
    var formData: any = new FormData();
    var modleString = JSON.stringify(this.moduleArray);
    var standardString = JSON.stringify(this.standardsArray);
    formData.append('NewFileStream', this.form.get('avatar').value);
    formData.append('Id', this.NewdocId);

    formData.append('PlantId', 1);
    formData.append('DepartmentId', this.department.departmentId);
    formData.append('Title', this.title);
    formData.append('Description', this.Description === "null" ? "" : this.Description);
    formData.append('Code', this.docsDetailsData.code);
    if(this.alternatedDocId!=null&&this.alternatedDocId!=""&&this.alternatedDocId!=undefined&&this.alternatedDocId != "null"){
      formData.append('AlternatedDocId', this.alternatedDocId);
    }
    else{
      formData.append('AlternatedDocId', "");
    }
    console.log("Selected workflow ID : " + this.selectedDocType.documentTypeId);
    formData.append('TypeId', this.selectedDocType.documentTypeId);
    if(this.selectedProduct!=null&&this.selectedProduct!=""&&this.selectedProduct!=undefined){
      if (this.selectedProduct.productGuid != null && this.selectedProduct.productGuid != undefined&&this.selectedProduct.productGuid!="") {
        if(this.selectedProduct.productGuid=="-1"){
          formData.append("ProductGuid", "");
        }
        else{
          formData.append("ProductGuid", this.selectedProduct.productGuid);
        }
      }
      else{
        formData.append("ProductGuid", "");
      }
    }
    else{
      formData.append("ProductGuid", "");
    }
    if(this.docsDetailsData.site!=null && this.docsDetailsData.site!=undefined){
      formData.append("Site", this.docsDetailsData.site);
    }
    formData.append('ChangeDescription', this.ECRNDescription);
        formData.append('ChangeReasons', this.ECRNReason);
        formData.append('Ecrn', this.selectedECRN);
        if(this.ECRNDate!=null&&this.ECRNDate!=undefined){
          formData.append('ChangeDate', this.datePipe.transform(this.ECRNDate, 'MM/dd/yyyy'));
        }
    if(this.effectiveDate !== '' && this.effectiveDate !== null){
      formData.append(
      'EffectiveDate',
      this.datePipe.transform(this.effectiveDate, 'MM/dd/yyyy')
    );}
    if(this.expirationDate !== '' && this.expirationDate !== null){
    formData.append(
      'ExpirationDate',
      this.datePipe.transform(this.expirationDate, 'MM/dd/yyyy')
    );}
    formData.append('Status', 'New');
    formData.append('Remarks', this.Remarks === "null" ? "" : this.Remarks);

    formData.append('Revision', this.revision);
    formData.append('RevisionParentId', 0);
    if(this.reviewDate !== '' && this.reviewDate !== null ){
    formData.append(
      'ReviewDate',
      this.datePipe.transform(this.reviewDate, 'MM/dd/yyyy')
    );
    }
    formData.append('IsArchived', 'N');
    let minor=false;
    if(this.selectedECN!=null&&this.selectedECN!=""&&this.selectedECN!=undefined)
    {
      minor = this.selectedECN.value==1?true:false;
    }
    if(this.selectedProductLine!=null&&this.selectedProductLine!=""&&this.selectedProductLine!=undefined)
    {
      if(this.selectedProductLine.id!="-1"){
        formData.append("ProductLineId", this.selectedProductLine.id);
      }
    }
    else{
      formData.append("ProductLineId", "");
    }
    formData.append("IsMinor", minor);
    formData.append('EcoParentId', this.docId);
    formData.append('HasWorkflow', true);
    formData.append('WorkflowDefinitionId', this.selectedWorkflow.workflowDefinitionId);
    // console.log("Selected workflow : " + this.selectedWorkflow);
    // console.log("Selected workflow ID : " + this.selectedWorkflow.workflowDefinitionId);
    formData.append('CreatedBy', this.userId);
    // formData.append('CreatedDate',  this.datePipe.transform(new Date(), 'MM/dd/yyyy'));
    // formData.append('LastAccessedBy', this.userId);
    // formData.append('LastAccessedDate', this.datePipe.transform(new Date(), 'MM/dd/yyyy'));
    formData.append('StandardClausesJson', standardString);
    formData.append('ModulesJson', modleString);
    if(action === 'sendforapproval' || action === 'confirm'){
      formData.append("Operation", "eco-sendforapproval");
    }
    else{
      formData.append("Operation", "eco");
    }
    formData.forEach((value, key) => {
      console.log(key + ' ' + value);
    });
    if(this.NewdocId > 0)
    {
      this._facadeService.UpdateDocument(formData)
      .subscribe(
        (data) => { 
          console.log(data);
          this.apiResponse = data;
          if(this.apiResponse.returnCode >= 0){ 
            this.saveAndSendApprovalDisplay=false;                  
            var TileStatus= (action=="sendforapproval"||action=="confirm")?"In-Process":"New";
              location.href=this.baseUrl["API_ENDPOINTS"]["DMSUI"]+'mydocs?TileStatus='+TileStatus;
          }
          else{
            this.responseError = this.apiResponse.returnMessage;
            this.showLoading = false;
          }
         
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
    else
    {
     this._facadeService.CreateDocument(formData)
      .subscribe(
        (data) => {
          console.log(data);
          this.apiResponse = data;
          if(this.apiResponse.returnCode >= 0){
            this.saveAndSendApprovalDisplay=false;    
            if(from){
              this.showLoading = false;
              this.NewdocId=this.apiResponse.returnObject.id;
            }
            else{
              var TileStatus= (action=="sendforapproval"||action=="confirm")?"In-Process":"New";
              location.href=this.baseUrl["API_ENDPOINTS"]["DMSUI"]+'mydocs?TileStatus='+TileStatus;
            }
          }
          else{
            this.responseError = this.apiResponse.returnMessage;
            this.showLoading = false;
          }
         
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
    }

  print() {
    var Content = document.getElementById('invoice-content').innerHTML;
    this.printService.Print(Content,this.docsDetailsData.title + " - " + this.docsDetailsData.revision + " ECO Documents",'trSearch',false);
  }
   exportExcel() {
       /* table id is passed over here */   
     let element = document.getElementById('excel-table'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     this.delete_row(wb.Sheets.Sheet1, 1);
     /* save to file */
     XLSX.writeFile(wb, this.docsDetailsData.title + " - " + this.docsDetailsData.revision + " ECO Documents.xlsx");
  }
  
  delete_row (ws, row_index){
    let range = XLSX.utils.decode_range(ws["!ref"])
    for(var R = row_index; R < range.e.r; ++R){
        for(var C = range.s.c; C <= range.e.c; ++C){
          ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
          if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
            ws[this.ec(R+1, C)].v  = "";
        }
    }
    range.e.r--
    delete (ws['F1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({r:r,c:c})
  }
  GetFile(){
    if(this.docsDetailsData.fileName!=null && this.docsDetailsData.fileName!=""&& this.docsDetailsData.fileName!=undefined){
      
      this.showLoading=true;
      var newname = this.docsDetailsData.userFileName.substr(0, this.docsDetailsData.userFileName.lastIndexOf('.'));
      this.Download(this.docsDetailsData.fileName,newname,this.docsDetailsData.userFileName);
    }
  }

  Download(filename,newname,userfilename)
{
  this._facadeService.downloadFile(filename,newname).subscribe(response => {
    response.filename = userfilename;
    let url = window.URL.createObjectURL(response);
    var anchor = document.createElement("a");
    anchor.download = userfilename;
    anchor.href = url;
    anchor.click();
    anchor.remove();
    // window.open(url, '_blank').focus();
    this.showLoading = false;
  }),
   error => console.log('Error downloading the file'),
  () => console.info('File downloaded successfully');

}
  BackClick(){
      location.href=this.baseUrl["API_ENDPOINTS"]["DMSUI"]+'docdetails';
  } 
}
